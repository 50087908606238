import axios from 'axios';
import {globalStates} from '../../../main.js'

export default{
    methods: {
        make(latitude, longitude) {
            return axios({
                method: "get",
                url: process.env.VUE_APP_API_URL + '/api/V1/property/favorites/geo-list',
                params: {
                    latitude: latitude,
                    longitude: longitude
                },
                headers: globalStates.headers.headers
            });
        }
    }
}