<template>
    <div class="center content-inputs">
        <h3 style="min-width: 600px;">Precios</h3>
        <br>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6" class="p-5">
                <vs-select placeholder="Precio mínimo" v-model="range_min" filter label="Precio mínimo:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="100000" label="$100.000">$100.000</vs-option>
                    <vs-option value="500000" label="$500.000">$500.000</vs-option>
                    <vs-option value="1000000" label="$1'000.000">$1'000.000</vs-option>
                    <vs-option value="2000000" label="$2'000.000">$2'000.000</vs-option>
                    <vs-option value="5000000" label="$5'000.000">$5'000.000</vs-option>
                    <vs-option value="10000000" label="$10'000.000">$10'000.000</vs-option>
                    <vs-option value="25000000" label="$25'000.000">$25'000.000</vs-option>
                    <vs-option value="50000000" label="$50'000.000">$50'000.000</vs-option>
                    <vs-option value="80000000" label="$80'000.000">$80'000.000</vs-option>
                    <vs-option value="110000000" label="$110'000.000">$110'000.000</vs-option>
                    <vs-option value="300000000" label="$300'000.000">$300'000.000</vs-option>
                    <vs-option value="500000000" label="$500'000.000">$500'000.000</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6" class="p-5">
                <vs-select placeholder="Precio máximo" v-model="range_max" filter label="Precio máximo:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="100000" label="$100.000">$100.000</vs-option>
                    <vs-option value="500000" label="$500.000">$500.000</vs-option>
                    <vs-option value="1000000" label="$1'000.000">$1'000.000</vs-option>
                    <vs-option value="2000000" label="$2'000.000">$2'000.000</vs-option>
                    <vs-option value="5000000" label="$5'000.000">$5'000.000</vs-option>
                    <vs-option value="10000000" label="$10'000.000">$10'000.000</vs-option>
                    <vs-option value="25000000" label="$25'000.000">$25'000.000</vs-option>
                    <vs-option value="50000000" label="$50'000.000">$50'000.000</vs-option>
                    <vs-option value="80000000" label="$80'000.000">$80'000.000</vs-option>
                    <vs-option value="110000000" label="$110'000.000">$110'000.000</vs-option>
                    <vs-option value="300000000" label="$300'000.000">$300'000.000</vs-option>
                    <vs-option value="500000000" label="$500'000.000">$500'000.000</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

        <h3 style="min-width: 600px;">Estructura</h3>
        <br>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Estrato" v-model="stratum" multiple filter  collapse-chips label="Estrato:">
                    <vs-option value="0" label="Estrato 0">Estrato 0</vs-option>
                    <vs-option value="1" label="Estrato 1">Estrato 1</vs-option>
                    <vs-option value="2" label="Estrato 2">Estrato 2</vs-option>
                    <vs-option value="3" label="Estrato 3">Estrato 3</vs-option>
                    <vs-option value="4" label="Estrato 4">Estrato 4</vs-option>
                    <vs-option value="5" label="Estrato 5">Estrato 5</vs-option>
                    <vs-option value="6" label="Estrato 6">Estrato 6</vs-option>
                </vs-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Habitaciones" v-model="rooms" multiple filter  collapse-chips label="Habitaciones:">
                    <vs-option value="1" label="1">1</vs-option>
                    <vs-option value="2" label="2">2</vs-option>
                    <vs-option value="3" label="3">3</vs-option>
                    <vs-option value="4" label="4">+4</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Baños" v-model="bathrooms" multiple filter  collapse-chips label="Baños:">
                    <vs-option value="1" label="1">1</vs-option>
                    <vs-option value="2" label="2">2</vs-option>
                    <vs-option value="3" label="3">3</vs-option>
                    <vs-option value="4" label="4">+4</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Parqueaderos" v-model="parking" multiple filter  collapse-chips label="Parqueaderos:">
                    <vs-option value="1" label="1">1</vs-option>
                    <vs-option value="2" label="2">2</vs-option>
                    <vs-option value="3" label="3">3</vs-option>
                    <vs-option value="4" label="4">+4</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Terrazas" v-model="terrace" multiple filter  collapse-chips label="Terrazas:">
                    <vs-option value="1" label="1">1</vs-option>
                    <vs-option value="2" label="2">2</vs-option>
                    <vs-option value="3" label="3">3</vs-option>
                    <vs-option value="4" label="4">+4</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Cocina" v-model="kitchen" filter label="Cocina:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Amoblado" v-model="furnished" filter label="Amoblado:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

        <h3 style="min-width: 600px;">Servicios</h3>
        <br>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Servicios incluidos" v-model="services_included" filter label="Servicios incluidos:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con electricidad" v-model="ligth" filter label="Con Electricidad:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con acueducto" v-model="water" filter label="Con acueducto:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>        

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con gas natural" v-model="gas" filter label="Con gas natural:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con internet" v-model="internet" filter label="Con internet:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con televisión por cable" v-model="tv_cable" filter label="Con televisión por cable:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con lavanderia" v-model="laundry_area" filter label="Con lavanderia:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>   

        <h3 style="min-width: 600px;">Otros</h3>
        <br>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con mascotas" v-model="pets" filter label="Con mascotas:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Zonas para niños" v-model="children_areas" filter label="Zonas para niños:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Zonas verdes" v-model="green_areas" filter label="Zonas verdes:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Con elevador" v-model="elevator" filter label="Con elevador:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="p-5">
                <vs-select placeholder="Porteria" v-model="lodge" filter label="Porteria:">
                    <vs-option value="n/a" label="No aplicar">No aplicar</vs-option>
                    <vs-option value="yes" label="Si">Si</vs-option>
                    <vs-option value="no" label="No">No</vs-option>
                </vs-select><br>
            </vs-col>
        </vs-row>

    </div>
</template>
<script>
export default {
    data:() => ({
        stratum: [],
        rooms: [],
        bathrooms: [],
        parking: [],
        terrace: [],
        kitchen: 'n/a',
        services_included: 'n/a',
        administration_included: 'n/a',
        biciparking_included: 'n/a',
        ligth: 'n/a',
        water: 'n/a',
        gas: 'n/a',
        internet: 'n/a',
        tv_cable: 'n/a',
        laundry_area: 'n/a',
        pets: 'n/a',
        furnished: 'n/a',
        children_areas: 'n/a',
        green_areas: 'n/a',
        elevator: 'n/a',
        lodge: 'n/a',
        range_min: 'n/a',
        range_max: 'n/a'
    })
}
</script>
<style>
.p-5{padding: 5px;}
</style>
