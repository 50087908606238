import Vue from "vue";
import App from "./App.vue";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import 'boxicons/css/boxicons.min.css';

Vue.use(Vuesax)

Vue.config.productionTip = false

export const globalStates = new Vue({
  data: {
    loggedIn: 0,
    favorites: 0,
    headers: {
      headers : {}
    },
    darkMode: false
  }
});

new Vue({
  data: {
    mapProperties: {
      location: [-74.070011, 4.607194],
      zoom: 16,
      mapStyle: 'build'
    },
    userProperties: {
      name: ''
    }
  },
  render: h => h(App),
}).$mount('#app')