<template>
    <vs-sidebar class="customSidebar" relative reduce v-model="active" open square>
        <vs-sidebar-group class="cs-first-element">
            <template #header>
                <vs-sidebar-item arrow>
                    <template #icon>
                        <i class='bx bx-merge'></i>
                    </template>
                    Capas
                </vs-sidebar-item>
            </template>
            <vs-sidebar-item>
                <template #icon>
                    <i class="bx bx-directions" @click="changeMapStyle('address')"></i>
                </template>
                Direcciones
            </vs-sidebar-item>
            <vs-sidebar-item>
                <template #icon>
                    <i class="bx bx-layer" @click="changeMapStyle('build')"></i>
                </template>
                3D
            </vs-sidebar-item>
            <vs-sidebar-item>
                <template #icon>
                    <i class="bx bx-crop" @click="changeMapStyle('area')"></i>
                </template>
                Area
            </vs-sidebar-item>
            <vs-sidebar-item>
                <template #icon>
                    <i class="bx bxs-bookmark-star" @click="changeMapStyle('qfy')"></i>
                </template>
                Calificación
            </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-item v-if="loggedIn == 1">
            <template #icon>
                <i v-if="favorites == 1" class="bx bxs-star" @click="toggleFavorites(false)"></i>
                <i v-else class="bx bx-star" @click="toggleFavorites(true)"></i>
            </template>
            Mis favoritos
        </vs-sidebar-item>
        <vs-sidebar-item>
            <template #icon>
                <i class="bx bx-current-location" @click="getMapLocation"></i>
            </template>
            Ubicación actual
        </vs-sidebar-item>
        <vs-sidebar-item class="cs-last-element">
            <template #icon>
                <i v-if="!darkModeModel" @click="changeTheme(true)" class="bx bxs-moon"></i>
                <i v-if="darkModeModel" @click="changeTheme(true)" class="bx bxs-sun"></i>
            </template>
            Estilo
        </vs-sidebar-item>
    </vs-sidebar>
</template>
<script>
//Repositories
import globalStatesRepository from '../../repositories/common/globalStatesRepository'

export default {
    components: {},
    data: () => ({
        active: false,
        darkModeModel: false,
        currentMapTheme: 'build'
    }),
    mixins: [globalStatesRepository],
    methods: {
        getMapLocation: function() {
            var thiss = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position){
                    thiss.$root.mapProperties.location = [
                        position.coords.longitude,
                        position.coords.latitude
                    ];
                }, function() {
                    thiss.$vs.notification({
                        color: 'warning',
                        text: 'Debes habilitar la geo localización.'
                    });
                });
            }
        },
        changeMapStyle: function(style) {
            this.currentMapTheme = style;
            this.$root.mapProperties.mapStyle = this.setDarkMode(style);
            localStorage.setItem('mapStyle', this.setDarkMode(style));
        },
        changeTheme: function(def = false) {
            if (def) {
                this.darkModeModel = !this.darkModeModel;
            }
            localStorage.setItem('darkMode', this.darkModeModel);
            document.getElementsByTagName('body')[0].setAttribute(
                'vs-theme',
                (this.darkModeModel) ? 'dark' : ''
            );
            document.getElementsByTagName('body')[0].className = (this.darkModeModel) ? 'dark' : '';
            this.$root.mapProperties.mapStyle = this.setDarkMode(this.currentMapTheme);
            localStorage.setItem('mapStyle', this.$root.mapProperties.mapStyle);
        },
        toggleFavorites: function(favorites) {
            var status = (favorites) ? 1 : 0;
            globalStatesRepository.methods.setFavorites(status);
            localStorage.setItem('favorites', status);
        },
        setDarkMode: function(style) {
            return style + ((this.darkModeModel) ? 'dark' : '');
        }
    },
    mounted() {
        if (localStorage.getItem('favorites') !== null) {
            globalStatesRepository.methods.setFavorites(localStorage.getItem('favorites'));
        }
        if (localStorage.getItem('mapStyle') !== null) {
            this.currentMapTheme = localStorage.getItem('mapStyle').replace('dark', '');
        }
        if (localStorage.getItem('darkMode') !== null) {
            this.darkModeModel = (localStorage.getItem('darkMode') === 'true');
            this.changeTheme();
        }
        if (localStorage.getItem('access_token') !== null) {
            globalStatesRepository.methods.setLoggedIn(1);
            globalStatesRepository.methods.setHeaders({
                "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
                "Access-Control-Allow-Origin": "*"
            });
            this.$root.userProperties.name = localStorage.getItem('name');
        }
    },
    computed: {
        loggedIn: function () {
            return globalStatesRepository.methods.getLoggedIn();
        },
        favorites: function () {
            return globalStatesRepository.methods.getFavorites();
        }
    }
}
</script>
<style>
.customSidebar{top: 90px !important;left: 1% !important;border-radius: 20px !important;}
.cs-first-element .vs-sidebar__group__header .vs-sidebar__item__icon:first-child{border-radius: 20px 20px 0 0 !important;}
.cs-last-element .vs-sidebar__item__icon:first-child{border-radius: 0 0 20px 20px!important;}
</style>
