import axios from 'axios';
import {globalStates} from '../../main.js'

export default{
    methods: {
        make() {
            return axios.post(process.env.VUE_APP_API_URL + '/api/V1/user/logout', {}, globalStates.headers);
        },
        unsetLocalStorageVars() {
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('access_token');
        }
    }
}