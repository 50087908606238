import {globalStates} from '../../main.js'

export default{
    methods: {
        setLoggedIn(status) {
            globalStates.loggedIn = status;
        },
        getLoggedIn() {
            return globalStates.loggedIn;
        },
        setHeaders(headers) {
            globalStates.headers.headers = headers;
        },
        getHeaders() {
            return globalStates.headers.headers;
        },
        setFavorites(favorites) {
            globalStates.favorites = favorites;
        },
        getFavorites() {
            return globalStates.favorites;
        }
    }
}