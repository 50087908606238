<template>
    <vs-navbar id="navbarContent" square center-collapsed>
        <template #left>
            <a href="/" class="home-link router-link-active" style="margin-right: 1em;">
                <img src="/img/logo.png" id="navLogo" style="height:32px; width: 120px;top: 2px;margin-bottom: -2px;">
            </a>
            <vs-navbar-item class="search-filter search-filter--type" hidden>
                <vs-select placeholder="Predio" v-model="propertyType" multiple filter collapse-chips>
                    <vs-option value="house" label="Casa">Casa</vs-option>
                    <vs-option value="apartment" label="Apartamento">Apartamento</vs-option>
                    <vs-option value="studio apartment" label="Apartaestudio">Apartaestudio</vs-option>
                    <vs-option value="lot" label="Lote">Lote</vs-option>
                    <vs-option value="house lot" label="Casa lote">Casa lote</vs-option>
                    <vs-option value="room" label="Habitación">Habitación</vs-option>
                    <vs-option value="country house" label="Casa rural">Casa rural</vs-option>
                    <vs-option value="warehouse" label="Bodega">Bodega</vs-option>
                    <vs-option value="office" label="Oficina / Consultorio">Oficina / Consultorio</vs-option>
                    <vs-option value="building" label="Edificio">Edificio</vs-option>
                </vs-select>
            </vs-navbar-item>
            <vs-navbar-item class="search-filter search-filter--sell" hidden>
                <vs-select placeholder="Tipo" name="tipo" v-model="propertySell" multiple filter collapse-chips>
                    <vs-option value="sale" label="Venta">Venta</vs-option>
                    <vs-option value="rental" label="Arriendo">Arriendo</vs-option>
                </vs-select>
            </vs-navbar-item>
            <vs-navbar-item hidden>
                <vs-button transparent @click="activeFiltersDialog = !activeFiltersDialog">
                    <i class="bx bx-filter"></i> Más filtros
                </vs-button>
            </vs-navbar-item>
        </template>
        <template v-if="loggedIn == 0" #right>
            <vs-button transparent @click="activeLoginDialog = !activeLoginDialog">Iniciar sesión</vs-button>
            <vs-button @click="activeRegisterDialog = !activeRegisterDialog">Regístrate</vs-button>
        </template>
        <template v-else #right>
            <vs-tooltip left>
                <vs-avatar primary circle>
                    <template #text>
                        {{initialName}}
                    </template>
                </vs-avatar>
                <template #tooltip>
                    {{name}}
                </template>
            </vs-tooltip>
            <vs-button transparent @click="logOut">Salir</vs-button>
        </template>
        <div v-if="loggedIn == 0">
            <!-- dialogs -->
            <vs-dialog v-model="activeLoginDialog">
                <Login />
            </vs-dialog>
            <vs-dialog v-model="activeRegisterDialog">
                <Register />
            </vs-dialog>
        </div>
        <vs-dialog v-model="activeFiltersDialog" scroll overflow-hidden auto-width>
            <Filters />
        </vs-dialog>
    </vs-navbar>
</template>
<script>
    //Repositories
    import globalStatesRepository from '../../repositories/common/globalStatesRepository'
    import userLogOutRepository from '../../repositories/user/logoutRepository'
    //Components
    import Register from '../user/Register.vue'
    import Login from '../user/Login.vue'
    import Filters from './Filters.vue'

    export default {
        components: {
            Register, Login, Filters
        },
        data: () => ({
            activeLoginDialog: false,
            activeRegisterDialog: false,
            activeFiltersDialog: false,
            activeLogOut: false,
            propertyType: [],
            propertySell: []
        }),
        mixins: [userLogOutRepository, globalStatesRepository],
        methods: {
            logOut: function() {
                var thiss = this;
                userLogOutRepository.methods.make()
                    .then(response => {
                        if (response.status == 200) {
                            globalStatesRepository.methods.setLoggedIn(0);
                            thiss.$vs.notification({
                                color: 'primary',
                                text: 'Hasta pronto <b>' + localStorage.getItem('name') + '</b>'
                            });
                            userLogOutRepository.methods.unsetLocalStorageVars();
                        } else {
                            thiss.$vs.notification({
                                color: 'danger',
                                text: 'Ups inténtlo de nuevo'
                            });
                        }
                    }).catch(function (error) {
                        if (error.response.status == 500) {
                            thiss.$vs.notification({
                                color: 'danger',
                                text: 'Ocurrio un error.'
                            });
                        }
                    });
            }
        },
        computed: {
            loggedIn: function () {
                return globalStatesRepository.methods.getLoggedIn();
            },
            name: function () {
                return this.$root.userProperties.name;
            },
            initialName: function () {
                if (this.$root.userProperties.name) {
                    return this.$root.userProperties.name.charAt(0)
                }
                return '';
            }
        }
    };
</script>

<style scoped>
.vs-navbar-content{padding: 10px 15px !important;width: 98% !important;left: 1%;top: 0.7em;border-radius: 20px !important;}
.vs-navbar-content .vs-navbar{padding: 0 !important;}
.vs-navbar__item{padding: 0 5px;}
.search-filter{margin: 0 7px !important;}
.search-filter--type , .search-filter--type .vs-select-content{max-width: 280px !important;width: 280px !important;}
.search-filter--sell , .search-filter--sell .vs-select-content{max-width: 210px !important;width: 210px !important;}
</style>