<template>
  <div>
    <Menu />
    <Mainmap />
    <SidebarLeft />
  </div>
</template>

<script>
  import Menu from './components/menu/Menu.vue'
  import Mainmap from './components/map/Mainmap.vue'
  import SidebarLeft from './components/menu/SidebarLeft.vue'

  export default {
  components: {
      Menu, Mainmap, SidebarLeft
    },
    data: () => ({
      darkModeModel: false
    }),
  };
</script>
<style>

</style>
