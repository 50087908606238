import axios from 'axios';
import {globalStates} from '../../../main.js'

export default{
    methods: {
        make(property_id, latitude, longitude, address) {
            return axios.post(process.env.VUE_APP_API_URL + '/api/V1/property/favorites/add', {
                property_id: property_id,
                latitude: latitude,
                longitude: longitude,
                address: address
            },globalStates.headers);
        }
    }
}