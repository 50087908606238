<template>
    <div id="container">
        <div id="map-element">
            <div id="map"></div>
            <vs-card id="contCard">
                <template #img>
                    <img src="#" width="0">
                </template>
                <template #title>
                    <h3></h3>
                </template>
                <template #text>
                    <vs-row>
                        <vs-col w="4" class="padding-4">
                            Dirección:
                        </vs-col>
                        <vs-col w="8" class="padding-4">
                            {{address}}
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col w="4" class="padding-4">
                            A.total:
                        </vs-col>
                        <vs-col w="8" class="padding-4">
                            {{area_t}}
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col w="4" class="padding-4">
                            A.Construida:
                        </vs-col>
                        <vs-col w="8" class="padding-4">
                            {{area_c}}
                        </vs-col>
                    </vs-row>
                </template>
                <template #interactions v-if="loggedIn === 1">
                    <vs-button primary icon class="toFavorites">
                        <i class='bx bx-star'></i>
                    </vs-button>
                    <!-- <vs-button primary icon class="toEdit">
                        <i class='bx bx-pencil'></i>
                    </vs-button>
                    <vs-button primary icon class="toCreate">
                        <i class='bx bx-plus'></i>
                    </vs-button> -->
                </template>
            </vs-card>
        </div>
    </div>
</template>
<script>
//Repositories
import globalStatesRepository from '../../repositories/common/globalStatesRepository'
import addFavoritesRepository from '../../repositories/property/favorites/addFavoritesRepository'
import getFavoritesListRepository from '../../repositories/property/favorites/getFavoritesListRepository'
//Components
import mapboxgl from 'mapbox-gl'
import numeral from 'numeral'

export default {
    data:() => ({
        initialLatitude: 0,
        initialLongitude: 0,
        endLatitude: 0,
        endLongitude: 0,
        mapToken: null,
        clickMarker: null,
        favoritesList: [],
        polygonInfo: null,
        map: null,
        lat: 0.0,
        lon: 0.0,
        area_t: '',
        area_c: '',
        property_id: '',
        address: ''
    }),
    mixins: [globalStatesRepository, addFavoritesRepository, getFavoritesListRepository],
    methods: {
        createMap: function(){
            if (localStorage.getItem('longitude') !== null && localStorage.getItem('latitude') !== null) {
                this.$root.mapProperties.location = [localStorage.getItem('longitude'), localStorage.getItem('latitude')];
            }
            if (localStorage.getItem('zoom') !== null) {
                this.$root.mapProperties.zoom = localStorage.getItem('zoom');
            }
            mapboxgl.accessToken = this.mapToken;
            var thiss = this;
            this.map = new mapboxgl.Map({
                container: "map",
                style: "./json/"+thiss.mapStyle+".json",
                center: thiss.location,
                zoom: thiss.zoom,
                maxZoom: 21,
                pitch: (thiss.mapStyle === 'build' || thiss.mapStyle === 'builddark') ? 60 : 0
            });
            this.map.on('click', function(e){
                thiss.lat = e.lngLat.lat;
                thiss.lon = e.lngLat.lng;
                if (thiss.map.getZoom() >= 15) {
                    var features = thiss.map.queryRenderedFeatures([
                        [e.point.x - 10 / 2, e.point.y - 10 / 2],
                        [e.point.x + 10 / 2, e.point.y + 10 / 2]
                    ]);
                    for (var polygon in features) {
                        if (features[polygon]['properties']['address']) {
                            thiss.polygonInfo = features[polygon];
                            break;
                        }
                    }
                    if (features[polygon]['properties']['address'] === undefined) {
                        if (thiss.clickMarker) {
                            thiss.clickMarker.remove();
                        }
                        return;
                    }

                    var el = document.createElement('button');
                    el.className = 'marker vs-button vs-button--null vs-button--size-null vs-button--active vs-button--icon vs-button--primary vs-button--default';
                    el.innerHTML = '<div class="vs-button__content"><i class="bx bx-map-pin"></i></div>';

                    if (e.originalEvent.target.className === 'mapboxgl-canvas') {
                        if (thiss.clickMarker) {
                            thiss.clickMarker.remove();
                        }
                        console.log(features[polygon]['properties']);
                        thiss.address = features[polygon]['properties']['address'];
                        thiss.property_id = features[polygon]['properties']['codigo'];
                        thiss.area_t = numeral(parseInt(features[polygon]['properties']['area_t'])).format('0,0') + ' m2';
                        thiss.area_c = numeral(parseInt(features[polygon]['properties']['area_c'])).format('0,0') + ' m2';
                        setTimeout(function(){
                            var cardHtml = document.getElementById('contCard').innerHTML;
                            thiss.clickMarker = new mapboxgl.Popup({ closeOnClick: false , maxWidth: '300px'})
                                .setLngLat([thiss.lon, thiss.lat])
                                .setHTML(cardHtml)
                                .addTo(thiss.map);
                            //handle events
                            if (document.getElementsByClassName('toFavorites').length > 0) {
                                document.getElementsByClassName('toFavorites')[0].addEventListener("click", function () {
                                    thiss.toFavorites();
                                }, false);
                                document.getElementsByClassName('toEdit')[0].addEventListener("click", function () {
                                    thiss.toEdit();
                                }, false);
                                document.getElementsByClassName('toCreate')[0].addEventListener("click", function () {
                                    thiss.toCreate();
                                }, false);
                            }
                        }, 100);
                    }
                }
            });
            this.map.on('moveend', function () {
                var distance = thiss.calcCrow(
                    localStorage.getItem('latitude'),
                    localStorage.getItem('longitude'),
                    thiss.map.getCenter().lat,
                    thiss.map.getCenter().lng
                );
                thiss.getFavorites(thiss.map.getCenter().lat, thiss.map.getCenter().lng, distance);
                localStorage.setItem('latitude', thiss.map.getCenter().lat);
                localStorage.setItem('longitude', thiss.map.getCenter().lng);
            });
            this.map.on('zoom', function (e) {
                console.log(e);
                if (thiss.map.getZoom() & 1) {
                    console.log('impar');
                }
            });
            this.map.on('zoomend', function() {
                thiss.$root.mapProperties.zoom = thiss.map.getZoom();
            });
        },
        toFavorites: function() {
            var thiss = this;
            addFavoritesRepository.methods.make(this.property_id, this.lat, this.lon, this.address)
                .then(response => {
                    if (response.status == 200) {
                        var el = document.createElement('div');
                        el.innerHTML = '<i class="bx bxs-star"></i>';
                        el.className = 'favorite-marker';
                        thiss.favoritesList.push(new mapboxgl.Marker(el)
                            .setLngLat([thiss.lon, thiss.lat])
                            .addTo(thiss.map)
                        );
                        thiss.$vs.notification({
                            color: 'primary',
                            text: 'Favorito agregado.'
                        });
                    }
                }).catch(function (error) {
                    if (error.response) {
                        thiss.$vs.notification({
                            color: 'danger',
                            text: 'Ocurrio un error agregando a favoritos.'
                        });
                    }
                });
        },
        getFavorites: function(latitude, longitude, distance) {
            if (this.favorites == 1 && this.loggedIn == 1 && distance >= 0.07) {
                var thiss = this;
                getFavoritesListRepository.methods.make(latitude, longitude)
                    .then(response => {
                        for (var favorite in response.data.data) {
                            let favoriteData = response.data.data[favorite];
                            var el = document.createElement('div');
                            el.innerHTML = '<i class="bx bxs-star"></i>';
                            el.className = 'favorite-marker';
                            thiss.favoritesList.push(new mapboxgl.Marker(el)
                                .setLngLat(favoriteData.point.coordinates)
                                .addTo(thiss.map)
                            );
                        }
                    }).catch(function (error) {
                        if (error.response) {
                            thiss.$vs.notification({
                                color: 'danger',
                                text: 'Ocurrio un error cargando los favoritos.'
                            });
                        }
                    });
            }
        },
        toEdit: function() {
            alert();
        },
        toCreate: function() {
            alert();
        },
        calcCrow: function(lat1, lon1, lat2, lon2) {
            var globalRadius = 6371; // km
            var dLat = this.toRad(lat2 - lat1);
            var dLon = this.toRad(lon2 - lon1);
            lat1 = this.toRad(lat1);
            lat2 = this.toRad(lat2);
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = globalRadius * c;
            return d;
        },
        toRad: function(value) {
            return value * 3.14159 / 180;
        }
    },
    created() {
        var thiss = this;
        setTimeout(function(){
            thiss.createMap();
            var minimumDistance = 0.07; // 700m
            thiss.getFavorites(
                localStorage.getItem('latitude'),
                localStorage.getItem('longitude'),
                minimumDistance,
            );
        }, 100);
    },
    watch: {
        mapStyle: function() {
            if (this.map !== null) {
                this.map.setStyle("./json/" + this.mapStyle + ".json");
                if (this.mapStyle === 'build' || this.mapStyle === 'builddark') {
                    this.map.setPitch(60);
                } else {
                    this.map.setPitch(0);
                }
            }
        },
        location: function() {
            this.map.flyTo({center: this.$root.mapProperties.location, zoom: this.$root.mapProperties.zoom});
        },
        zoom: function() {
            localStorage.setItem('zoom', Math.trunc(this.map.getZoom()));
        }
    },
    computed: {
        favorites: function () {
            return globalStatesRepository.methods.getFavorites();
        },
        loggedIn: function () {
            return globalStatesRepository.methods.getLoggedIn();
        },
        mapStyle: function() {
            return this.$root.mapProperties.mapStyle;
        },
        location: function() {
            return this.$root.mapProperties.location;
        },
        zoom: function() {
            return this.$root.mapProperties.zoom;
        }
    }
}
</script>
<style>
html, body {height: 100%;width: 100%;padding:0px;margin: 0;}
#container {height: 100%;width: 100%;padding:0px;position: absolute;left: 0;top: 0;}
#map-element , #map{height: 100%;}
#map-element #contCard{display: none;}
#map-view , #map-element {width: 100%;}
.marker {cursor: pointer;font-size: 2em;}
.mapboxgl-popup-content{background: transparent !important;padding: 0 !important;}
body.dark .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{border-top-color: #1e2023;}
body.dark .mapboxgl-popup-anchor-top .mapboxgl-popup-tip{border-bottom-color: #1e2023;}
body.dark .mapboxgl-popup-anchor-left .mapboxgl-popup-tip{border-right-color: #1e2023;}
body.dark .mapboxgl-popup-anchor-right .mapboxgl-popup-tip{border-left-color: #1e2023;}
.vs-card__interactions{position: relative !important;margin: auto;}
.mapboxgl-popup-content .vs-card{width: 300px;}
.mapboxgl-popup-content .vs-card__img img{display: none;}
.favorite-marker i{color:#ffba00;font-size: 1.5em;}
</style>