<template>
    <div class="center content-inputs">
        <div class="con-form">
            <br>
            <vs-input v-model="name" placeholder="Nombre" class="w-100">
                <template #icon>
                    <i class='bx bxs-user'></i>
                </template>
                <template v-if="name.length < 2 && name !== ''" #message-danger>
                    Nombre demasiado corto
                </template>
            </vs-input>
            <br>
            <vs-input v-model="email" placeholder="Correo electrónico" class="w-100" @change="email_duplicate=false">
                <template #icon>@ </template>
                <template v-if="!validEmail" #message-danger>
                    <span v-if="email_duplicate">Correo ya existe</span>
                    <span v-else-if="email !== ''">Correo inválido</span>
                </template>
            </vs-input>
            <br>
            <vs-input type="password" v-model="password" placeholder="Contraseña" class="w-100">
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
                <template v-if="!validPassword && password !== ''" #message-danger>
                    Contraseña insegura
                </template>
            </vs-input>
            <br>
            <vs-input type="password" v-model="password_confirmation" placeholder="Repetir contraseña" class="w-100">
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
                <template v-if="!validateConfirm && password_confirmation !== ''" #message-danger>
                    Las contraseñas no son iguales
                </template>
            </vs-input>
            <br>
        </div>
        <div class="footer-dialog">
            <vs-button block @click="register" :disabled="!validEmail || !validPassword || !validateConfirm || name.length < 2">
                Registrarme
            </vs-button>
        </div>
    </div>
</template>
<script>
//Repositories
import registerRepository from '../../repositories/user/registerRepository'

export default {
    data:() => ({
        active: false,
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        email_duplicate: false,
        remember: false
    }),
    mixins: [registerRepository],
    methods: {
        register: function() {
            var thiss = this;
            registerRepository.methods.make(this.name, this.email, this.password, this.password_confirmation)
                .then(response => {
                    if (response.status == 200) {
                        thiss.email_duplicate = false;
                        thiss.closeModal(document.querySelector('.vs-dialog__close'));
                        thiss.$vs.notification({
                            color: 'primary',
                            title: 'Usuario creado',
                            text: '<b>' + thiss.name + '</b>, por favor logueate para continuar.'
                        });
                    } else {
                        thiss.$vs.notification({
                            color: 'danger',
                            text: 'Ups inténtlo de nuevo'
                        });
                    }
                }).catch(error => {
                    if (error.response.status == 422) {
                        var errorBag = error.response.data.errors;
                        if (errorBag['email'] !== undefined) {
                            thiss.email_duplicate = true;
                            thiss.email = '';
                        }
                    }
                    if (error.response.status == 500) {
                        thiss.$vs.notification({
                            color: 'danger',
                            text: 'Ocurrio un error.'
                        });
                    }
                });
        },
        closeModal: function(elem) {
            var evt = new MouseEvent('click', {bubbles: true,cancelable: true,view: window});
            !elem.dispatchEvent(evt);
        }
    },
    computed: {
        validEmail() {
          return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
        },
        validPassword() {
            return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.password)
        },
        validateConfirm() {
            return (this.password === this.password_confirmation)
        }
    }
}
</script>
<style>
  .vs-input{width: 100% !important}
</style>
