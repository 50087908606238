import axios from 'axios';

export default{
    methods: {
        make(name, email, password, password_confirmation) {
            return axios.post(process.env.VUE_APP_API_URL + '/api/V1/user/register', {
                name: name,
                email: email,
                password: password,
                password_confirmation: password_confirmation
            });
        }
    }
}