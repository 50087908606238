import axios from 'axios';

export default{
    methods: {
        make(email, password, rememberme) {
            return axios.post(process.env.VUE_APP_API_URL + '/api/V1/user/login', {
                email: email,
                password: password,
                rememberme: rememberme,
            });
        },
        setLocalStorageVars(response) {
            localStorage.setItem('name', response.data.userName);
            localStorage.setItem('email', response.data.userEmail);
            localStorage.setItem('access_token', response.data.access_token);
        }
    }
}