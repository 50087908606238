<template>
    <div class="center content-inputs">
        <div class="con-form">
            <br>
            <vs-input v-model="email" placeholder="Correo electrónico" class="w-100">
                <template #icon>@ </template>
                <template v-if="!validEmail && email !== ''" #message-danger>
                    Correo inválido
                </template>
            </vs-input>
            <br>
            <vs-input type="password" v-model="password" placeholder="Contraseña" class="w-100">
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
            </vs-input>
            <p v-if="noAccess === 1" class="text--danger text-align--center">Correo y/o contraseña incorrectos.</p>
            <br>
            <div class="flex">
                <vs-checkbox v-model="rememberme">Recordarme</vs-checkbox>
            </div>
        </div>
        <div class="footer-dialog">
            <vs-button block @click="login" :disabled="!validEmail || !validPassword">
                Entrar
            </vs-button><br>
            <vs-button transparent block>
                ¿Olvidó su contraseña?
            </vs-button>
        </div>
    </div>
</template>
<script>
//Repositories
import globalStatesRepository from '../../repositories/common/globalStatesRepository'
import userLogInRepository from '../../repositories/user/loginRepository';

export default {
    data:() => ({
        email: '',
        password: '',
        rememberme: false,
        noAccess: 0
    }),
    mixins: [userLogInRepository, globalStatesRepository],
    methods: {
        login: function() {
            var thiss = this;
            thiss.noAccess = 0;
            userLogInRepository.methods.make(this.email, this.password, this.rememberme)
                .then(response => {
                    if (response.status == 203) {
                        thiss.$vs.notification({
                            color: 'danger',
                            text: 'Ups inténtlo de nuevo'
                        });
                    }
                    else if (response.status == 200) {
                        var someLink = document.querySelector('.vs-dialog__close');
                        thiss.closeModal(someLink);
                        userLogInRepository.methods.setLocalStorageVars(response.data);
                        thiss.$root.userProperties.name = response.data.data.userName;
                        globalStatesRepository.methods.setLoggedIn(1);
                        globalStatesRepository.methods.setHeaders({
                            "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
                            "Access-Control-Allow-Origin": "*"
                        }); 
                        thiss.$vs.notification({
                            color: 'primary',
                            text: 'Bienvenido <b>' + localStorage.getItem('name') + '</b>'
                        });
                    }
                }).catch(function (error) {
                    if (error.response.status == 500) {
                        thiss.$vs.notification({
                            color: 'danger',
                            text: 'Ocurrio un error.'
                        });
                    }
                });
        },
        closeModal: function(elem) {
            var evt = new MouseEvent('click', {bubbles: true,cancelable: true,view: window});
            !elem.dispatchEvent(evt);
        }
    },
    computed: {
        loggedIn: function () {
            return globalStatesRepository.methods.getLoggedIn();
        },
        validEmail() {
          return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
        },
        validPassword() {
            return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.password)
        }
    }
}
</script>
<style>
  .vs-input{width: 100% !important}
</style>
